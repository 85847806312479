import { useState } from "react"
import BaseDiscoverComponent from "../Discover/BaseDiscoverComponent"
import { AvatarConfig } from "../../app/types"
import { useDispatch } from "react-redux"
import { setCurrentAvatarConfigAndGetSuggestedTopics } from "../../app/redux/actions"
import { useCookies } from "react-cookie"
import { authDataKey } from "../../app/const-keys"

export default ({ onStartSession }: {
    onStartSession: () => void
}) => {
    const dispatch = useDispatch()

    const [avatarsSideMargin, setAvatarsSideMargin] = useState(0)
    const [areAvatarsLoaded, setAreAvatarsLoaded] = useState(false)

    const [cookies] = useCookies<string>([authDataKey])
    const authData = cookies[authDataKey]

    const welcomeText = (authData.first_name?.length > 0) ? `Welcome, ${authData.first_name.trim()}!` : 'Welcome!'

    return (
        <div className='overflow-scroll h-full pt-[64px] pb-[50px] w-full'>
            <div>
                <div style={{
                    marginLeft: avatarsSideMargin,
                    display: areAvatarsLoaded ? undefined : 'none',
                }}>
                    <div className='discover__welcome-text'>
                        {welcomeText}
                    </div>
                    <div className='discover__subtitle mb-[32px] mt-[8px]'>
                        Select an avatar to continue
                    </div>
                </div>
                <div className='text-center flex content-center'>
                    <div>
                        <BaseDiscoverComponent
                            addLastUsed
                            onSelect={(avatarConfig: AvatarConfig) => {
                                dispatch(setCurrentAvatarConfigAndGetSuggestedTopics(avatarConfig))
                                onStartSession()
                            }}
                            onSideMarginCalculated={(margin: number) => setAvatarsSideMargin(margin)}
                            onLoaded={() => setAreAvatarsLoaded(true)}
                        />
                    </div>
                </div>
            </div>
            {!areAvatarsLoaded && <div className='text-center discover__subtitle'>Loading...</div>}
        </div>
    )
}
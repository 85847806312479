import { useState } from "react"
import ProfileWidget from "./ProfileWidget"
import BaseDiscoverComponent from "../Discover/BaseDiscoverComponent"
import { useDispatch } from "react-redux"
import { AvatarConfig } from "../../app/types"
import { setCurrentAvatarConfigAndGetSuggestedTopics } from "../../app/redux/actions"
import { useNavigate } from "react-router-dom"
import PopupComponent from "../Infra/PopupComponent"
import PopupDiscoverComponent from "../FirstScreens/PopupDiscoverComponent"

const HeaderComponent = ({ intro }: {
    intro?: boolean
}) => {

    const [showDiscover, setShowDiscover] = useState(false)
    const navigate = useNavigate()

    return (
        <>
            <div className='header'>

                <img
                    onClick={() => navigate('/')}
                    src='/images/logo-long.png'
                    className='h-[40px] cursor-pointer'
                />

                <div className='flex-grow'></div>

                {!intro && <div className='header__button' onClick={() => setShowDiscover(true)}>Discover avatars </div>}

                <ProfileWidget />

            </div>

            {showDiscover && <PopupDiscoverComponent close={() => setShowDiscover(false) }/> }
        </>
    )
}

export const headerHeight = 75
export default HeaderComponent